<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show text-uppercase">Материалы партнеров
          </h2>
        </div>
      </div>
      <div class="row pt-4 d-flex flex-column-reverse flex-lg-row">
        <div class="col-12 col-lg-3">
          <div class="pt-2 pt-lg-0">
            <rasp-slider></rasp-slider>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row d-flex align-items-stretch">
            <div class="col-12 col-md-6 col-lg-4 pb-4 d-flex align-items-stretch" v-for="partner in getPartners"
                 :key="partner.id">
              <div class="partner">
                <div class="partner__image">
                  <img :src="partner.avatar" :alt="partner.name">
                </div>
                <div class="partner__files d-flex flex-wrap pt-4">
                  <div class="partner__file pb-2" v-for="file in partner.PartnerFiles" :key="file.id">
                    <a :href="file.link" target="_blank">{{ file.name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import RaspSlider from "../components/components/RaspSlider";

export default {
  name: "Materials",
  components: {RaspSlider},
  data() {
    return {
      partners: []
    }
  },
  computed: {
    getPartners() {
      if (!this.partners.length) {
        return this.partners
      }
      return _.sortBy(_.filter(this.partners, item => {
        item.PartnerFiles = _.sortBy(item.PartnerFiles, ['order'])
        return item.PartnerFiles.length
      }), ['order'])
    }
  },
  mounted() {
    this.$axios.get('partner-all')
      .then(resp => {
        this.partners = resp.data
      })
      .catch(e => {
        console.log(e)
      })
  }
}
</script>

<style scoped lang="scss">
.section_votes {
  padding: rem(30) 0;
}

.partner {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: rem(60) rem(30) rem(24);
  @include media(768) {
    padding: rem(15);
  }

  &__image {
    //padding: 0 rem(20);
    text-align: center;

    img {
      max-width: 100%;
      vertical-align: top;
    }
  }

  &__buttons {
    padding-top: rem(20);
  }

  &__button {
    cursor: pointer;

    svg {
      * {
        transition: all .25s;
      }
    }

    &:hover {
      svg {
        circle {
          fill: #59B980
        }
      }
    }

    &:first-child {
      margin-right: rem(5);
    }
  }

  &__file {
    padding-right: rem(18);

    a {
      font-size: rem(12);
      color: #52B5E0;
      text-decoration: none;
    }
  }
}
</style>
